const _ = require('lodash');
const fingerprint = localStorage.getItem('fingerprint');
const backendUrl =   process.env.NODE_ENV === 'production' ? 'http://lapommebriarde.fr/back' : 'http://localhost:5050';

export async function getCart() {
  const params = { fingerprint : fingerprint }

  let response = await fetch(`${backendUrl}/cart/${params.fingerprint}`);

  const articles = await response.json();
  return articles;
}

export async function addToCart(id) {
  let items = await getCart()
  items.push(id)

  const data = {
    'fingerprint' : fingerprint,
    'items' : items
  }

  let response = await fetch(`${backendUrl}/cart`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });

  const articles = await response.json();
  return articles;
}

export async function removeFromCart(id) {
  let items = await getCart()

  const index = _.indexOf(items, id);
  if (index !== -1) {
    items.splice(index, 1); 
  }

  const data = {
    'fingerprint' : fingerprint,
    'items' : items
  }

  let response = await fetch(`${backendUrl}/cart`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });

  const articles = await response.json();
  return articles;
}

export async function deleteFromCart(id) {
  let items = await getCart()
  _.pull(items, id);

  const data = {
    'fingerprint' : fingerprint,
    'items' : items
  }

  let response = await fetch(`${backendUrl}/cart`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });

  const articles = await response.json();
  return articles;
}
