import * as React from "react";

// Popin
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {Form} from "react-router-dom";

export default class Login extends React.Component {  
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
    };
  }

  handleDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      isDialogOpen: !prevState.isDialogOpen
    }));
  };

  render(){
    return (
      <div id="login" className="Login">
        {/*<Button title="Login" color="inherit" onClick={this.handleDialog}>
            login
        </Button>*/}
        <Dialog
          open={this.state.isDialogOpen}
          onClose={this.handleDialog}
          PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData).entries());
              const email = formJson.email;
              this.handleDialog();
            },
          }}
        >
          <DialogTitle>Se connecter</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Afin de se connecter veuillez renseigner vos identifiants.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="email"
              name="email"
              label="Adresse mail"
              type="email"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="password"
              name="password"
              label="Mot de passe"
              type="password"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialog}>Annuler</Button>
            <Form method="post">
              <Button type="submit">Se connecter</Button>
            </Form>
          </DialogActions>
        </Dialog>
        </div>
    );
  }
}