import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BFD561',  // vert clair
      contrastText: '#2D0C06',  // marron foncé pour un bon contraste sur le vert clair
    },
    secondary: {
      main: '#5E771B',  // vert olive
      contrastText: '#FFFFFF',  // texte blanc pour contraste
    },
    background: {
      default: '#F5F5F5',  // couleur de fond légère et neutre
      paper: '#FFFFFF',  // fond pour les éléments en papier
    },
    text: {
      primary: '#2D0C06',  // marron foncé pour le texte principal
      secondary: '#5E771B',  // vert olive pour le texte secondaire
    },
  },
  typography: {
    fontFamily: 'Montserrat, Roboto, Arial, sans-serif',
  },
});

export default theme;