import * as React from 'react';

import '@fontsource/roboto/300.css';
import './contact.scss';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import Avatar from '@mui/material/Avatar';


export default class Contact extends React.Component {  
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  Address() {
    return (
      <Card className="Contact">
        <CardContent>
          <div className="Center">
            <Avatar >
              <LocationOnIcon />
            </Avatar>
          </div>
          <Typography  sx={{  m: '0.5rem', fontWeight: 'bold' }}>
            Adresse
          </Typography>
          <Typography variant="subtitle" className="Adress">
            9 Glatigny,  <br/>
            77120 Saints, <br/>
            France
          </Typography>
        </CardContent>
      </Card>
    );
  }

  Email() {
    return (
      <Card className="Contact">
        <CardContent>
          <div className="Center">
            <Avatar >
              <Email />
            </Avatar>
          </div>
          <Typography  sx={{ m: '0.5rem', fontWeight: 'bold' }}>
            Email
          </Typography>
          <Typography variant="subtitle" className="Adress">
            lapommebriarde@gmail.com
          </Typography>
        </CardContent>
      </Card>
    );
  }

  Phone() {
    return (
      <Card className="Contact">
        <CardContent>
          <div className="Center">
            <Avatar >
              <Phone />
            </Avatar>
          </div>
          <Typography  sx={{ m: '0.5rem', fontWeight: 'bold' }}>
            Téléphone
          </Typography>
          <Typography variant="subtitle" className="Adress">
            (+33)(0)7 52 62 32 77
          </Typography>
        </CardContent>
      </Card>
    );
  }

  render(){
    return (
      <div id="contact" className="Contact">
        <Grid container>
          <Grid  xs={4} spacing={-20} sx={{minWidth: 200, maxWidth: 200 }}  container >
            {<this.Address />}
          </Grid>
          <Grid  xs={4} spacing={-20}  sx={{minWidth: 200, maxWidth: 200}} container >
            {<this.Email />}
          </Grid>
          <Grid  xs={4} spacing={-20}  sx={{minWidth: 200, maxWidth: 200}} container >
            {<this.Phone />}
          </Grid>
        </Grid>
      </div>
    );
  }
}
