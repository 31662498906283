import { Link } from "react-router-dom";
import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { getCart } from "../cart/service";
import { getStore} from "../store/service";

import { useLoaderData } from "react-router-dom";

const backendUrl =   process.env.NODE_ENV === 'production' ? 'http://lapommebriarde.fr/back' : 'http://localhost:5050';

const _ = require('lodash');

const stripePromise = loadStripe("pk_live_51QHNy3JT2smbfqTx8K5tKBwOLBjRY2cey422WPP60wFrytolTHwZ1lLh3BYEvwpBMybGFJAGbI6zIJds56IfuBJN00YODRWeId")//"pk_test_51QHNy3JT2smbfqTxbil8yQMqKWVaS8E1LZrf6LJknMfr0rB9tFBUzs02dabUV4AYjVUg9ts6lkdXia9Uuf1MqzgZ00MbJT7aHa");

function Return () {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    if (sessionId) {
      fetch(`${backendUrl}/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }
  }, []);

  if (status === 'open') {
    return (
      <Link to={'payment'} className="Link">paiement</Link>
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          Merci pour votre commande ! Un email de confirmation sera envoyé à {customerEmail}.

          Si vous avez des questions, envoyez un mail à : <a href="mailto:lapommebriarde@gmail.com">lapommebriarde@gmail.com</a>.
        </p>
      </section>
    )
  }

  return null;
}

export default function Payment() {
  const { items } = useLoaderData();
  const filteredItems = items.filter(item => item.id === "65f45c8fe7ea985ab0f0f96a");
  const count = filteredItems.length;
  const prices = filteredItems.map(item => item.price);
  const countById = _.countBy(items, "_id");

  const data = {
    'price' : prices[0],
    'quantity' : count
  }

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch(`${backendUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = {fetchClientSecret};

  return (
    <div className="Payment">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      <Return />
    </div>
  )
}

export async function loader() {
  let items = await getCart();
  const articles = await getStore();

  // Transformation
  items = _.map(items, (id) => {
    const matchingItem = _.find(articles, { _id: id });
    return matchingItem
      ? { id, ...matchingItem }  
      : { id };
    });

  return { items };
}