import * as React from 'react';
import '@fontsource/roboto/300.css';
import './store.scss';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Input from '@mui/material/Input';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import { useLoaderData, Link, Form,} from "react-router-dom";
import { getStore,  } from "./service";
import { addToCart } from "../cart/service"
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from '@mui/material';

import { useDrawer } from "../cart/DrawerContext";

const drawerWidth = 500;

const _ = require('lodash');


//import Items from "./interface"
//const cart : Items = []

function Filtre(){
  return (
    <Drawer variant="permanent" anchor="left" className="Drawer"sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            height: 'calc(100% - 150px)', 
            top: 82
      }}}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 }
        }}
        noValidate
        autoComplete="off"
      >
        <TextField id="rechercher" label="Rechercher" variant="outlined" />
      </Box>
    </Drawer>
  );
}

export default function Store(){
  const { isDrawerOpen } = useDrawer();
  const { items } = useLoaderData();
  return (
    <div id="store" className="Store">
      {/*<Filtre />*/}

      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, display: 'flex', transition: (theme) =>
            theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: isDrawerOpen ? `${drawerWidth}px` : 0,
          padding: 3, }} >
        <Grid container >
          {items
          .filter((item) => item.quantite > 0)
          .map((item) => (
            <Grid key={item._id} spacing={-5} container  >
              <Card className="item" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardActionArea component={Link}> {/* to={`store/${item._id}`}*/}
                  <CardMedia
                    component="img"
                    image={`${_.kebabCase(item.titre)}.jpg`}
                    alt={item.type}
                    sx={{ width: "auto", height: 200, objectFit: "contain", margin: "auto" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.titre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>

                <CardActions sx={{ mt: 'auto' }}>
                  <Form method="post">
                    <Input type="hidden" name="id" defaultValue={item._id} />
                    <Button id="add-to-cart" size="small" color="primary" type="submit" >
                      Ajouter au panier
                    </Button>
                  </Form>
                  <Typography className='Card_footer'>
                    {item.prix}€{item.type === 'pomme' ? ' / kg' : ''}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export async function loader() {
  const items = await getStore();
  return { items };
}

export async function action({ request, params }) {
  const formData = await request.formData();
  let id = formData.get("id");
  //const updates = Object.fromEntries(formData);
  const cart = await addToCart(id);
  return { cart };
}