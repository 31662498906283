import * as React from 'react';

// CSS
import "./cart.scss";

import { getCart, addToCart, removeFromCart, deleteFromCart } from "./service";
import { getStore} from "../store/service";

import { Form, useLoaderData } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

import { Box, Card, CardContent, CardMedia, Button, Divider } from '@mui/material';


import { useDrawer } from "./DrawerContext";

const _ = require('lodash');

const drawerWidth = 500;

const MyDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

export default function Cart(props){
  const navigate = useNavigate();

  const { isDrawerOpen, toggleDrawer } = useDrawer();

  const { items } = useLoaderData();

  const countById = _.countBy(items, "_id");

  return (
    <div id="cart" className="Cart">
      {isDrawerOpen && (
        <MyDrawer variant="persistent" anchor="right" open={isDrawerOpen}>
          <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto', my: 4, p: 2, borderRadius: 2 }}>
            <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>Panier</Typography>
            {_.isEmpty(countById) ? (
              <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center' }}>Votre panier est vide.</Typography>
            ) : (
            <>
            {_.entries(countById).map(([id, quantite]) => (
              <Card key={id} sx={{ display: 'flex', mb: 2 }}>
                <CardMedia
                  component="img"
                  sx={{ width: 100 }}
                  image={`${_.kebabCase(_.find(items, { _id: id }).titre)}.jpg`}
                  alt={_.find(items, { _id: id }).type}
                  sx={{ width:100 , height: 100, objectFit: "contain", margin: "auto" }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <Typography variant="h6">{_.find(items, { _id: id }).titre}</Typography>
                  <Typography color="text.secondary">Prix: €{_.find(items, { _id: id }).prix.toFixed(2)}</Typography>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <Form
                      method="post"
                      action={`cart/${id}/remove?redirectTo=${encodeURIComponent(window.location.pathname)}`}
                    >
                      <IconButton type="submit" color="primary">
                        <RemoveIcon />
                      </IconButton>
                    </Form>
                    <Typography variant="body1" sx={{ mx: 1 }}>{quantite}</Typography>
                    <Form
                      method="post"
                      action={`cart/${id}/add?redirectTo=${encodeURIComponent(window.location.pathname)}`}
                    >
                      <IconButton type="submit" color="primary">
                        <AddIcon />
                      </IconButton>
                    </Form>
                  </Box>
                </CardContent>
                <Form
                  method="post"
                  action={`cart/${id}/destroy?redirectTo=${encodeURIComponent(window.location.pathname)}`}
                >
                  <IconButton type="submit" color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Form>
              </Card>
            ))}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Total:</Typography>
              <Typography variant="h6">€{_.sumBy(items, 'prix')}</Typography>
            </Box>
            <Button variant="contained" fullWidth sx={{ mt: 3 }} onClick={() => navigate('/payment')}>
              Passer à la caisse
            </Button>
            </>
            )}
          </Box>
        </MyDrawer>
      )}
    </div>
  );
}

export async function addItem(id) {
  let items = await addToCart(id);
  return items
}

export async function removeItem(id) {
  let items = await removeFromCart(id);
  return items
}

export async function deleteItem(id) {
  let items = await deleteFromCart(id);
  return items
}


export async function loader() {
  let items = await getCart();
  const articles = await getStore();

  // Transformation
  items = _.map(items, (id) => {
    const matchingItem = _.find(articles, { _id: id });
    return matchingItem
      ? { id, ...matchingItem }  
      : { id };
    });

  return { items };
}