import React, { useState } from 'react';
import { Box, Typography, IconButton, Card, CardContent, CardMedia, Button, Divider } from '@mui/material';
import { Add, Remove, Delete } from '@mui/icons-material';

const Test = () => {
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: 'Jus de pomme frais',
      price: 4.5,
      quantity: 2,
      image: 'https://via.placeholder.com/100'
    },
    {
      id: 2,
      name: 'Confiture de pommes',
      price: 6,
      quantity: 1,
      image: 'https://via.placeholder.com/100'
    },
  ]);

  const handleAddQuantity = (id) => {
    setCartItems(cartItems.map(item => item.id === id ? { ...item, quantity: item.quantity + 1 } : item));
  };

  const handleRemoveQuantity = (id) => {
    setCartItems(cartItems.map(item => item.id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item));
  };

  const handleRemoveItem = (id) => {
    setCartItems(cartItems.filter(item => item.id !== id));
  };

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto', my: 4, p: 2, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>Panier</Typography>
      
      {cartItems.length === 0 ? (
        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center' }}>Votre panier est vide.</Typography>
      ) : (
        <>
          {cartItems.map((item) => (
            <Card key={item.id} sx={{ display: 'flex', mb: 2 }}>
              <CardMedia
                component="img"
                sx={{ width: 100 }}
                image={item.image}
                alt={item.name}
              />
              <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Typography variant="h6">{item.name}</Typography>
                <Typography color="text.secondary">Prix: €{item.price.toFixed(2)}</Typography>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <IconButton onClick={() => handleRemoveQuantity(item.id)} color="primary">
                    <Remove />
                  </IconButton>
                  <Typography variant="body1" sx={{ mx: 1 }}>{item.quantity}</Typography>
                  <IconButton onClick={() => handleAddQuantity(item.id)} color="primary">
                    <Add />
                  </IconButton>
                </Box>
              </CardContent>
              <IconButton onClick={() => handleRemoveItem(item.id)} color="error" sx={{ alignSelf: 'center' }}>
                <Delete />
              </IconButton>
            </Card>
          ))}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Total:</Typography>
            <Typography variant="h6">€{getTotalPrice()}</Typography>
          </Box>
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
            Passer à la caisse
          </Button>
        </>
      )}
    </Box>
  );
};

export default Test;